import React, { useState } from "react";
import Layout from "../components/Layout/Layout";

import { useToggle } from "@react-md/utils";

import Hero from "../components/Students/Hero";
import QuickTour from "../components/Students/QuickTour";
import CreateStudentForm from "../components/Students/CreateStudentForm";
import GoogleOrEmailSignUp from "../components/shared/GoogleOrEmailSignUp";

import "../styles/pages/students.scss";
import "../styles/components/Layout/layout.scss";
import "../styles/pages/responsiveness.scss";

export default function Students(props) {
  let code;
  let register;
  if (props && props.location && props.location.search) {
    let searchParams = new URLSearchParams(props.location.search);
    code = searchParams.get("code");
    register = !!searchParams.get("register");
  }
  const [visible, enable, disable] = useToggle(!!code || register); // auto opens create student modal if code is in url
  const [visibleSignUpType, enableSignUpType, disableSignUpType] =
    useToggle(false);
  const [accountData, setAccountData] = useState(null);

  const handleSignUpTypeSelection = (payload) => {
    const { selection, data, target } = payload;

    if (selection === "google") setAccountData(data);

    // open correct modal
    if (target === "student") enable();
  };

  return (
    <Layout>
      <Hero enable={enable} />
      <QuickTour enable={enable} />
      <CreateStudentForm
        visible={visible}
        enable={enable}
        disable={disable}
        accountData={accountData}
        code={code}
      />
      {visibleSignUpType ? (
        <GoogleOrEmailSignUp
          visible={visibleSignUpType}
          enable={enableSignUpType}
          disable={disableSignUpType}
          selection={handleSignUpTypeSelection}
          target="student"
        />
      ) : (
        <></>
      )}
    </Layout>
  );
}
