import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import { Button } from "react-md";

function Hero(props) {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(
          relativePath: { eq: "images/Students Page/Rectangle 3.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <section>
      {/* <div className="students__hero"> */}
      <BackgroundImage
        Tag="section"
        className="students__hero"
        fluid={imageData}
        backgroundColor={`#303d4e`}
      >
        <div className="container students__hero-shadow">
          <div>
            <h2 className="students__hero-sub-header">DeltaMath for</h2>
          </div>
          <h1 className="students__hero-header">Students</h1>
        </div>
        {/* </div> */}
        <div className="students__createAccount">
          <div className="container students__createAccount-container">
            <div className="students__createAccount-description">
              <p>Ready to begin? Just click the 'Register' button.</p> 
              <p>(You'll need a class code to create an account.)</p>
            </div>
            <div className="students__createAccount-createAccount">
              <Button
                className="students__createAccount-button"
                theme="secondary"
                themeType="contained"
                onClick={props.enable}
              >
                Register
              </Button>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/* </div> */}
    </section>
  );
}

export default Hero;
